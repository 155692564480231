<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <div class="mt-[-88px]">
      <div
        class="max-h-[calc(100vh-40px)] overflow-hidden relative mb-10 gradient-overlay"
      >
        <MediaImage
          v-if="hero"
          v-blokkli-droppable:field_hero_image="{
            entityType: 'media',
            entityBundles: ['image'],
            cardinality: 1,
          }"
          hide-caption
          v-bind="entity?.hero || hero"
          preload
          loading="eager"
          :image-style="bigGrid"
          class="w-full"
        />
        <Container>
          <div class="grid-container absolute bottom-6 md:bottom-12">
            <h1
              v-blokkli-editable:title
              class="text-4xl md:text-5xl lg:text-7xl xl:text-8xl text-white col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-8 hyphens-none"
            >
              {{ entity?.title || title }}
            </h1>
          </div>
        </Container>
      </div>
      <div v-if="lead" class="grid-container">
        <div class="grid-container-8 pb-10">
          <div
            v-blokkli-editable:field_lead="{ type: 'frame' }"
            class="text-xl md:text-2xl"
            v-html="lead"
          />
        </div>
      </div>
      <div>
        <BlokkliField :list="paragraphs" name="field_paragraphs" />
      </div>
    </div>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  lead?: string
  businessArea?: NodePageFragment['businessArea']
  hero?: NodePageFragment['hero']
  paragraphs?: NodePageFragment['paragraphs']
  canEdit?: NodePageFragment['canEdit']
  blokkliProps: NodePageFragment['blokkliProps']
}>()

const bigGrid = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 770,
      aspectRatio: 1,
    },
    sm: {
      width: 985,
      aspectRatio: 16 / 9,
    },
    md: {
      width: 1381,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 2561,
      aspectRatio: 16 / 9,
    },
  },
})

// Provide the business area to views
provide('businessArea', '' + props.businessArea?.id)
</script>
